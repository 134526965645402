import * as React from "react";
import { Helmet } from "react-helmet";
import logo from "../images/icon.png";

import * as StyledComponents from "../assets/styles/index.module.scss";

// markup
const IndexPage = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Web Longueuil</title>
        <script src="EQCSS.js"></script>
      </Helmet>
      <div className={StyledComponents.absoluteContainer}>
        <div className={StyledComponents.textImageContainer}>
          <img src={logo} alt="Web longueuil logo" />
          <div className={StyledComponents.textContainer}>
            <h1>
              Web <br /> Longueuil
            </h1>
            <p>Plus qu'un site web</p>
          </div>
        </div>
      </div>
      <div className={StyledComponents.figuresContainer}>
        <div className={StyledComponents.yring}></div>
        <div className={StyledComponents.rtriangle}></div>
        <div className={StyledComponents.bdot}></div>
        <div className={StyledComponents.rring}></div>
        <div className={StyledComponents.ydot}></div>
        <div className={StyledComponents.btriangle}></div>
        <div className={StyledComponents.bring}></div>
      </div>
    </>
  );
};

export default IndexPage;
