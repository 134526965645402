// extracted by mini-css-extract-plugin
export var absoluteContainer = "index-module--absoluteContainer--ppxbq";
export var textImageContainer = "index-module--textImageContainer--2neYG";
export var textContainer = "index-module--textContainer--27oQR";
export var figuresContainer = "index-module--figuresContainer--auDLN";
export var yring = "index-module--yring--1grfM";
export var floatYringx = "index-module--floatYringx--2IpnW";
export var rtriangle = "index-module--rtriangle--1tEbj";
export var floatRtriangle = "index-module--floatRtriangle--2PcGC";
export var bdot = "index-module--bdot--2vG__";
export var floatBdot = "index-module--floatBdot--3-khq";
export var rring = "index-module--rring--_E76f";
export var floatRring = "index-module--floatRring--2r2AZ";
export var ydot = "index-module--ydot--gBsOb";
export var floatYdot = "index-module--floatYdot--3tnBz";
export var btriangle = "index-module--btriangle--3wg-7";
export var floatBtriangle = "index-module--floatBtriangle--ljLip";
export var bring = "index-module--bring--2H30y";
export var floatBring = "index-module--floatBring--FaYbV";